export const info_function = {
    info_Helsinki(){
        var data=`The Helsinki case focuses on applications of satellite observations for air pollution
        monitoring to serve users in both the public and private sectors in Finland.<br><br>
        The maps show the average of the satellite-based NO2 (Nitrogen dioxide) tropospheric
        columns retrieved from TROPOMI (TROPOspheric Monitoring Instrument) over the period
        1.4.-30.9.2019 as well as the surface concentrations converted from satellite data based on
        the method by <a class="info-text" href="https://iopscience.iop.org/article/10.1088/1748-9326/aba3a5" target="_blank">Cooper et al.</a>, 2020. The average maps have been gridded at 1 km spatial
        resolution and cover the area around Helsinki. TROPOMI, onboard the ESA’s Sentinel 5
        Precursor (S5P) satellite, is part of the EU Copernicus Earth Observation program. To
        enhance the NO2 signal close to the emission sources, only TROPOMI data corresponding to
        calm wind conditions (wind speed smaller than 3 m/s) have been included in the maps. The
        color-coded pins indicate the surface concentrations at the AQ stations averaged over the
        period 1.4.-30.9.2019 using the same colour scale as the surface concentration map. The
        green pins indicate the known stationary NO2 emission sources in 2019 from the <a class="info-text" href="https://industry.eea.europa.eu/" target="_blank">E-PRTR
        database</a>. Other sources of emissions come mainly from the transportation sector (road and
        shipping). NO2 is a polluting gas produced mainly from fossil fuel combustion processes, i.e.
        from transportation, energy production and industrial processes. NO2 has negative effects
        on human health and the environment. Concentrations of short-lived gases like NO2 can be
        used as proxies of NO2 emissions when averaged in time and space, since averaging
        removes some of the short-term variability due to changing meteorological conditions.
        As an example of application, we collaborated with the Finnish company <a class="info-text" href="https://www.neste.com/products/all-products/neste-my-sustainable-aviation-fuel/neste-my-saf-for-business?gclid=CjwKCAiA24SPBhB0EiwAjBgkhkIdszFqrgAn7rj6oEIIlhgKFC38EaZPr5q8urlTuHaNrNKRVhCeLRoCo40QAvD_BwE" target="_blank">NESTE</a>, that
        operates an oil refinery in the city of Porvoo, about 40 km East from Helsinki. We combined
        TROPOMI NO2 observations with traditional AQ measurements to monitor the changes in
        air quality levels in the vicinity of the refinery. The results were used by the company for
        both environmental monitoring and sustainability reporting as shown for example <a class="info-text" href="https://ir-service.appspot.com/view/ahBzfmlyLXNlcnZpY2UtaHJkchsLEg5GaWxlQXR0YWNobWVudBiAgNCRp4PpCQw?language_no=0" target="_blank">here</a>.
        We also collaborated with the <a class="info-text" href="https://ym.fi/en/front-page" target="_blank">Finnish Ministry of the Environment</a> in assessing the air
        quality level in Finland to report to the European Environmental Agency (EEA). We used for
        the first time satellite observations to complement traditional ground-based AQ
        measurements in official AQ reporting. We developed ad-hoc methods to convert the
        satellite-based observations into surface concentrations and to estimate NO2 surface
        concentrations over areas where measurements from the AQ network are not available. The
        results have been summarized in a comprehensive <a class="info-text" href="https://helda.helsinki.fi/handle/10138/334054" target="_blank">report</a> used by the Finnish authorities to
        compile the national AQ assessment to the EEA.
        Satellite-based NO2 maps of other Finnish cities as well as more details about the data are
        available <a class="info-text" href="https://sampo.fmi.fi/airpollution/no2/" target="_blank">here</a>.`

        return data
    },
    info_Munich(){
        var data=`The Aggregated Risk Index (ARI) is an index used to assess the impact of air quality on health (e.g.:
            mortality due to all causes, mortality due to cardiovascular disease) caused by the exposure to air
            pollutant mixture (PM<sub>2.5</sub> , PM<sub>10</sub> , NO<sub>2</sub> , O<sub>3</sub> , SO<sub>2</sub> ).
            <br>
            <br>
            Parameter displayed:
            <br>
            <div class="table-responsive">
            <table class="table table-dark">
                <thead>
                    <tr>
                    <th scope="col">PARAMETER</th>
                    <th scope="col">Statistical aggregate</th>
                    <th scope="col">Temporal aggregation</th>
                    <th scope="col">Spatial aggregation</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>ARI</td>
                    <td>Mean, maximum</td>
                    <td>Yearly</td>
                    <td>ZIP code</td>
                    </tr>
                    <tr>
                    <td>Pollutants concentration</td>
                    <td>Mean, maximum, standard deviation</td>
                    <td>Yearly</td>
                    <td>ZIP code</td>
                    </tr>
                    <tr>
                    <td>Exposure (µg / m<sub>3</sub> *
                        inhab per 100.000
                        inhabitants)</td>
                    <td>Mean, maximum, standard deviation</td>
                    <td>Yearly</td>
                    <td>ZIP code</td>
                    </tr>
                </tbody>
                </table>
                </div>
            <br>
            Time coverage: 2016- 2019
            
            <br>
            Spatial coverage: Munich metropolitan region
            <br>
            <br>
            <p style="text-decoration: underline;">Data origin:</p>
            <lu>
            <li>Polyphemus/DLR Chemical Transport Model,</li>
            <li>Native spatial resolution: ca. 1km<sup>2</sup> (Augsburg-Munich region/domain), ca. 12 km x 6kmkm (Central
            Europe domain),</li>
            <li>Native temporal resolution: hourly,</li>
            </lu>
            <br>
            Find out more: <a class="info-text" href="https://www.alpendac.eu/eshape" target="_blank">https://www.alpendac.eu/eshape</a> 
            `

        return data
    },
    info_Megacities(){
        var data=`The Aggregated Risk Index (ARI) is an index used to assess the impact of air quality on health (e.g.:
            mortality due to all causes, mortality due to cardiovascular disease) caused by the exposure to air
            pollutant mixture (PM<sub>2.5</sub> , PM<sub>10</sub> , NO<sub>2</sub> , O<sub>3</sub> , SO<sub>2</sub> ).
            ARI is here given at the Current (in red) and future (in green) Megacities in the world (Data Source: World Urban Prospect 2018, United Nations Department of Economic and Social Affairs Population Dynamics).

            <br>
            <br>
            Parameter displayed : ARI monthly means.
            <br>
            <br>
            <p style="text-decoration: underline;">Data origin:</p>
            <li>Copernicus Atmospheric Monitoring Service, global re-analysis,</li>
            <li>Native spatial resolution: 0.75°x0.75°,</li>
            <li>Native temporal resolution: 3-hourly.</li>
            <br>
            Time coverage: 2015-2020
            <br>
            Find out more:<a href="https://www.nextgeoss-airquality.de/" target="_blank" > here</a>.
            `

        return data
    },
    info_Athens(){
        var data=`The urban area of Athens exhibits increased levels of atmospheric pollutants and high population
        density. Their combination reflects the added value product of hotspots of residential air pollution in
        high resolution maps (1 by 1 km<sup>2</sup> , or 100 by 100 m<sup>2</sup> in the intra-urban area).
        <br>
        <br>
        The mean monthly population exposure fields provided for Athens are derived from applications of the
        newly developed high resolution chemical transport model <a class="info-text" href="https://zenodo.org/record/4814060/#.YehIAv5ByUk" target="_blank">Episode-Citychem</a> (<a class="info-text" href="https://gmd.copernicus.org/articles/12/3357/2019/" target="_blank">Matthias et al., 2019</a>;
        <a class="info-text" href="https://www.mdpi.com/2073-4433/12/11/1404" target="_blank">Ramacher et al., 2021</a>). Simulations by the WRF mesoscale weather prediction model provide the high-
        resolution meteorological inputs, while the boundary conditions of air quality and the emission data are
        derived by the Copernicus Atmospheric Monitoring Service (<a class="info-text" href="http://www.regional.atmosphere.copernicus.eu/" target="_blank">CAMS</a>). The downscaling of emission fields
        to the desired spatial resolution is performed by UrbEm (<a class="info-text" href="https://www.mdpi.com/2073-4433/12/11/1404" target="_blank">Ramacher et al., 2021</a>), following a spatial
        disaggregation approach utilizing high resolution, credible high-resolution proxies. The tool represents
        road transport emissions as line sources and industrial stack as point sources (the rest as area sources).
        Hourly model outputs are combined with in situ measurements (<a class="info-text" href="http://84.205.254.113/airqualmap/leafletmap.html" target="_blank">National Monitoring Network</a>,  <a class="info-text" href="http://apcg.meteo.noa.gr/index.php/infrastructure/thissio-ams" target="_blank">NOA
        supersite</a>, low and mid-cost smart  <a class="info-text" href="https://air-quality.gr/" target="_blank">sensors</a>) towards the systematic evaluation of model results.
        <br>
        The Air Quality Index (from measurements and model results) is calculated in consistency to the European Environment Agency. Find out more at <a class="info-text" href="https://airindex.eea.europa.eu/Map/AQI/Viewer" target="_blank">https://airindex.eea.europa.eu/Map/AQI/Viewer</a>
        
        `

        return data
    },
    info_Bari(){
        var data=`
        The Sustainable Development Goal, SDG 11 (of <a class="info-text" href="https://sdgs.un.org/2030agenda" target="_blank">The 2030 Agenda for Sustainable Development</a>), aims to “make cities and human settlements inclusive, safe, resilient and sustainable.” Indicator 11.6.2 refers to the “Annual mean levels of fine particulate matter (e.g. PM2.5 and PM10) in cities (population weighted)”. The UN official approach for reporting on this indicator is to have the country’s national statistical offices or responsible agency (e.g. Ministries of Health or Environment) report a population weighted mean for the country.<br><br>
        For this case study, the year of 2019 is selected to acquire gridded PM pollution concentration data (by <a class="info-text" href="https://www.arpa.puglia.it/" target="_blank">ARPA Puglia</a>). The population density is calculated at the high spatial resolution of  100x100 meters (<a class="info-text" href="https://www.mdpi.com/2072-4292/13/14/2835" target="_blank">Aquilino et al., 2021</a>). These data are combined to estimate an equivalent of the official SDG11.6.2 numbers both for fine (PM2.5) and total (PM10) particulate matter, but on the intra-urban rather than the aggregated at the country level.`
        return data
    }


        
}