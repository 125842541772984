import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import router from './routes/routes'
Vue.config.productionTip = false
import { LMap, LTileLayer, LMarker,LFeatureGroup} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-feature-group', LFeatureGroup);
import VueHighcharts from 'vue2-highcharts'
import Highcharts from "highcharts";
import Stock from 'highcharts/modules/stock'
import HighchartsVue from "highcharts-vue";
Stock(Highcharts);
Vue.use(HighchartsVue);
Vue.component('vue-highcharts',VueHighcharts);


import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
// router setup
import { BootstrapVueIcons } from 'bootstrap-vue'
import { BootstrapVue, IconsPlugin ,BIcon, BIconArrowUp, BIconArrowDown } from 'bootstrap-vue'
Vue.component('BIcon', BIcon)
Vue.component('BIconArrowUp', BIconArrowUp)
Vue.component('BIconArrowDown', BIconArrowDown)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
//Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
delete L.Icon.Default.prototype._getIconUrl;
import L from 'leaflet';
L.Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


import { DraggablePlugin, DraggableDirective } from '@braks/revue-draggable';

// Use as Plugin
Vue.use(DraggablePlugin)

// or
Vue.directive('draggable', DraggableDirective)

new Vue({
  el: '#app',
  render: h => h(App),
  router,
  
})