<template>
    <div>
        <div class="d-flex flex-row px-1"  >
            <div class="box3 d-flex align-items-center justify-content-center" v-for="(color,index) in legend_data_color" :key="index+'legend'" :style="{'background-color':color,'font-size': '15px'}" >{{legend_data_value[index]}}</div>
            
        </div>               
    </div>
</template>


<script>
export default {
  props: {
    bari_data: Object
  },
data(){
    return{
        legend_data_value,
        legend_data_color
    }
},
  created(){
    console.log(this.bari_data)
    this.legend_data_color=this.bari_data.legend.color
    this.legend_data_value=this.bari_data.legend.value
  }
}

</script>

<style >
.box3 {
    width: 40px;
    /* height: 22px; */
}
</style>