<template>
<div>

<!--<TopBar></TopBar>-->
<div class=" shadow-lg w-100" style="z-index:1000;top: 0; left: 0px;background: #383838">
            
            <div class="d-flex justify-content-between align-items-center ">
              <div class="px-2">
               <h4 class="text-light" style="padding: 0px!important;font-family: 'Poppins', sans-serif;">
               The <span style="color:#dc3545">Teaser</span> Platform of Health Surveillance Air Quality Pilot (HSAQ, <span style="color:#dc3545">e</span>-shape)
              </h4>
              </div>
           
            <div class="">
              <div id="mySidenav" class="sidenav">
                <a href="javascript:void(0)" class="closebtn" @click="closeNav">&times;</a>
                <a data-toggle="modal" data-target="#aboutModal" class="pointer">About</a>
                <a data-toggle="modal" data-target="#PartnersModal" class="pointer">Partners</a>
                <a >Disclaimer</a>
                <a data-toggle="modal" data-target="#ContactModal" class="pointer">Contact</a>
              </div>
              <div class="col-md-12 d-flex justify-content-between pointer" >
              <!--<h2 class="d-flex align-items-center text-white" style="padding: 0px;!important">
               Teaser
              </h2>-->
              <span class="burger-menu text-light" align-v="center" @click="openNav">&#9776;</span>
              </div>
            </div>
            </div>
            
          </div>
<div class="" style="background-color:#c7c7c7" >
    <div class="">
        
        <div id="app" class="" >  
            
          <div class="modal fade" id="aboutModal" tabindex="-1" role="dialog" aria-labelledby="aboutModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="aboutModalLabel">About</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div>
                  <p>e-shape is a unique initiative that brings together decades of public investment in Earth Observation and in cloud capabilities into services for the decision-makers, the citizens, the industry and the researchers. It allows Europe to position itself as global force in Earth observation through leveraging Copernicus, making use of existing European capacities and improving user uptake of the data from GEO assets. 42 pilot applications under 7 thematic areas address societal challenges, foster entrepreneurship and support sustainable development. More information about the project can be found here.</p>
                  <br>
                  <p>Pilot 2.3 (PI Evangelos Gerasopoulos, NOA), which is the Health Surveillance Air Quality Pilot (HSAQ),  is focused on EO-based pollution-health risks profiling in the urban environment. The objectives of this pilot are</p>
                  <ul>
                  <li>To improve AQ-health information, from air pollution data and land use/health/socio-economic features of cities, for use in public health assessment and urban planning.</li>
                  <li>To evaluate and exploit citizen science data towards their integration with the official measurements.</li>
                  <li>To strengthen decision making by allowing for pollution mitigation scenarios and provision of alerts.</li>
                  <li>To raise awareness on AQ implications on public health, enhancing the citizen participation.</li>
                  </ul>
                  <br>
                  <p>This online platform is a <strong>TEASER</strong> – a *marketplace of services* with global and unique city components utilizing a variety of existing EO infrastructure for health surveillance purposes. The information and services provided here have been based on the specific needs of the selected cities, showing off different EO driven products to address city particularities. Through the teaser demonstration, the user may be redirected to the individual platforms of each component to explore its full database and potential.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
           <div class="modal fade" id="PartnersModal" tabindex="-1" role="dialog" aria-labelledby="PartnersModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="PartnersModaldalLabel">Partners</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  
                  <div class="row ">
                  <div class="col-4 d-flex text-center">
                    <a href="https://www.noa.gr/" target="_blank" class="align-self-center">
                    <img src="img/noa.jpg" class="w-50"/>
                    </a>
                  </div>
                   <div class="col-4 d-flex text-center">
                    <a href="https://www.dlr.de/EN/Home/home_node.html" target="_blank" class="align-self-center">
                      <img src="img/DLR_Logo.png" class="w-50"/>
                    </a>
                  </div>
                   <!--<div class="col-4 d-flex text-center">
                    <a href="https://www.noa.gr/" target="_blank" class="align-self-center">
                      <img src="img/noa.jpg" class="w-50 "/>
                    </a>
                  </div>-->
                   <div class="col-4 d-flex">
                    <a href="https://draxis.gr/" target="_blank" class="align-self-center">
                      <img src="img/draxis.png" class="w-100"/>
                    </a>
                  </div>
                   <div class="col-4 d-flex mt-5">
                    <a href="https://iiasa.ac.at/" target="_blank" class="align-self-center" >
                      <img src="img/iiasalogo50.png " class="w-100 " />
                    </a>
                  </div>
                   
                  <div class="col-4 d-flex mt-5">
                    <a href="https://iia.cnr.it/" target="_blank" class="align-self-center">
                      <img src="img/CNR.png" style="width:100%"/>
                    </a>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
           <div class="modal fade" id="ContactModal" tabindex="-1" role="dialog" aria-labelledby="ContactModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="ContactModalLabel">Contact</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>Contact HSAQ at:</p>
                  <br>
                  <div class="d-flex">
                    <p>Email: </p>
                  <a href="mailto:egera@noa.gr"> egera@noa.gr </a>
                  </div>
                  <br>
                  <div class="d-flex">
                    <p>Phone:</p>
                  <a href="tel:+30 2108109124"> +30 2108109124 </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
           <div class="position-absolute" style="z-index:1000;top: 93px; left: 0px;">
              <div class=" d-flex justify-content-between ">
                <b-button data-toggle="modal" data-target="#settingsModal" size="sm" class="py-3 sm-screen" variant="danger" style="z-index:1001;border-radius: 0px 10px 10px 0px!important;">
                  <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
                </b-button>
                <b-button size="sm" class="py-3 lg-screen" @click="show_settings=!show_settings" variant="danger" style="z-index:1001;border-radius: 0px 10px 10px 0px!important;">
                  <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
                </b-button>
                
                

                <transition name="fade">
                <div class=" d-flex align-items-center px-2 py-4 " style="background:#383838" v-if="show_settings">
                
                <div>
                    <b-form-select size="sm" v-model="selected_country"  @change="ChangeSelectedCountry">
                                
                                  <template #first>
                                    <b-form-select-option :value="null" disabled>-- Select City--</b-form-select-option>
                                  </template>
                                
                      <!--<b-form-select-option :value="0" >Μαρκόπουλο</b-form-select-option> 
                      <b-form-select-option :value="1" >Πικροδάφνη</b-form-select-option> area_of_interest_data-->
                      <b-form-select-option v-for="(country,index) in marker_countries" :key="index+'country'" :value="JSON.parse(country).name">{{JSON.parse(country).name}}</b-form-select-option>
                      
                      <!--<b-form-select-option value="average">Αverage</b-form-select-option>-->
                      
                    </b-form-select>
                    
                </div>
                
                  <div v-if="selected_country==='Athens (GRC)'" class="d-flex center-control align-items-center text-light h-100">
                    <div class="px-2 h-100">
                      <div class="vertical-line"></div>
                      </div>
                     <div class="px-1">
                        <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-checkbox-group
                          v-model="selected_point_greece"
                          :options="options_point_greece"
                          :aria-describedby="ariaDescribedby"
                        ></b-form-checkbox-group>
                      </b-form-group>
                      </div>
                      <div class="px-2 h-100">
                      <div class="vertical-line"></div>
                      </div>
                      <div class="">
                        <b-form-select size="sm" v-model="selected_gridGreece" @change="ChangeSelectedGridGreece" >
                                    
                                      <template #first>
                                        <b-form-select-option :value="null" disabled>-- Select Grid--</b-form-select-option>
                                      </template>
                                    
                          <!--<b-form-select-option :value="0" >Μαρκόπουλο</b-form-select-option> 
                          <b-form-select-option :value="1" >Πικροδάφνη</b-form-select-option> area_of_interest_data-->
                          <b-form-select-option value="pm25" html-field="html">PM <sub>2.5</sub></b-form-select-option>
                          <b-form-select-option value="no2">NO <sub>2</sub></b-form-select-option>
                          <b-form-select-option value="aqi">AQI</b-form-select-option>
                          <!--<b-form-select-option value="average">Αverage</b-form-select-option>-->
                          
                        </b-form-select>
                      
                       <!-- <b-form-select size="sm" disabled v-model="selected_yearGreece" @change="ChangeSelectedGridGreece" >
                                    
                                      <template #first>
                                        <b-form-select-option :value="null" disabled>-- Select Year--</b-form-select-option>
                                      </template>
                                    
                 
                          <b-form-select-option v-for="(year, index) in layer_years_Greece" :key="index+'yearGreece'" :value="year">{{year}}</b-form-select-option>
                          
                        </b-form-select>-->
                      
                      </div>
                      <div class="px-2">
                        <div class="d-flex justify-content-center align-items-center w-100 text-light">
                      
                          <b>Layer opacity:</b>
                          <vue-slider v-model="valueOpacity" class="w-25 px-2"></vue-slider>
                        
                        </div>
                      <div class=" text-center d-flex align-items-center" style="width:500px">
                          <b>Month:</b>
                          <div class="w-100 px-3">
                          <vue-slider
                              v-model="selected_monthGreece"
                              :data="layer_months_Greece"
                              @change="ChangeSelectedGridGreece"
                            ></vue-slider>
                          </div>
                      </div>
                    
                    
                      </div>
                   
                  </div>
                  <div v-if="selected_country==='Helsinki (FIN)'" class="d-flex center-control align-items-center text-light h-100">
                    <div class="px-2 h-100">
                      <div class="vertical-line"></div>
                    </div>
                    <div class="">
                    <b-form-select size="sm" v-model="selected_gridHelsinki" class="ml-auto" @change="ChangeSelectedGrid">
                                
                                  <template #first>
                                    <b-form-select-option :value="null" disabled>-- Select Grid--</b-form-select-option>
                                  </template>
                                
                      <!--<b-form-select-option :value="0" >Μαρκόπουλο</b-form-select-option> 
                      <b-form-select-option :value="1" >Πικροδάφνη</b-form-select-option> area_of_interest_data-->
                      <b-form-select-option value="teaser:tropos_flip_2019">Tropospheric</b-form-select-option>
                      <b-form-select-option value="teaser:surface_2019">Surface</b-form-select-option>
                      <!--<b-form-select-option value="average">Αverage</b-form-select-option>-->
                      
                    </b-form-select>
                    
                  </div>
                  
                    <div class="text-light px-2">
                      <b>Layer opacity:</b>
                      <vue-slider v-model="valueOpacity" class=""></vue-slider>
                    </div>
                     <div class="px-2 h-100">
                      <div class="vertical-line"></div>
                    </div>
                    <div class="">
                    
            
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      id="checkbox-group-1"
                      v-model="selected_radio"
                      :options="options_radios"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-1"
                    ></b-form-checkbox-group>
                  </b-form-group>
                  
                  </div>
                  </div>
                  <div v-if="selected_country==='Munich (GER)'"  class=" d-flex center-control align-items-center justify-content-center w-100 text-light h-100">
                     <div class="px-2 h-100">
                      <div class="vertical-line"></div>
                    </div>
                <div  class="py-2">
                    <b-form-select size="sm" v-model="selected_aggregation" class="ml-auto" @change="ChangeSelectedAggregation">
                                
                                  <template #first>
                                    <b-form-select-option :value="null" disabled>-- Select Aggregation--</b-form-select-option>
                                  </template>
                                
                      <!--<b-form-select-option :value="0" >Μαρκόπουλο</b-form-select-option> 
                      <b-form-select-option :value="1" >Πικροδάφνη</b-form-select-option> area_of_interest_data-->
                      <b-form-select-option value="mean">Mean</b-form-select-option>
                      <b-form-select-option value="max">Max</b-form-select-option>
                      <!--<b-form-select-option value="average">Αverage</b-form-select-option>-->
                      
                    </b-form-select>
                    
                  </div>
                  <div class="w-100 text-center d-flex align-items-center">
                    <b>Year:</b>
                    <div class="w-100 px-4">
                    <vue-slider
                        v-model="valueYear"
                        :marks="true"
                        :data="dataYear"
                        :included="true"
                      ></vue-slider>
                    </div>
                  </div>
                  </div>
                </div> 
                </transition>
               
                
                
              </div>
              
               
              
               
           </div>
           <div class="position-absolute " style="z-index:1000;top: 181px; left: 0px;">
             <b-button size="sm"  style="z-index:1001;border-radius: 0px 10px 10px 0px!important;" class="mt-2 py-3" variant="primary" @click="Reset">
                <b-icon icon="arrow-counterclockwise" aria-hidden="true"></b-icon>
              </b-button>
           </div>
           <div class="modal fade" id="settingsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Settings</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        
              <div class="">
              
              <div class="">
                  <b-form-select  v-model="selected_country" class="ml-auto" @change="ChangeSelectedCountry">
                              
                                <template #first>
                                  <b-form-select-option :value="null" disabled>-- Select City--</b-form-select-option>
                                </template>
                              
                  
                    <b-form-select-option v-for="(country,index) in marker_countries" :key="index+'country'" :value="country.name">{{country.name}}</b-form-select-option>
                    
              
                    
                  </b-form-select>
                  
              </div>
                <div v-if="selected_country==='Athens (GRC)'" class="text-dark">
                  <div class="py-2">
                    <b-form-select  v-model="selected_gridGreece" class="ml-auto" @change="ChangeSelectedGridGreece" >
                                
                                  <template #first>
                                    <b-form-select-option :value="null" disabled>-- Select Grid--</b-form-select-option>
                                  </template>
                                

                      <b-form-select-option value="pm25" html-field="html">PM <sub>2.5</sub></b-form-select-option>
                      <b-form-select-option value="no2">NO <sub>2</sub></b-form-select-option>
                      <b-form-select-option value="aqi">AQI</b-form-select-option>

                     
                      
                    </b-form-select>
                  </div>
                  <div class="">
                    <b-form-select  v-model="selected_yearGreece" disabled class="ml-auto" @change="ChangeSelectedGridGreece"  >
                                
                                  <template #first>
                                    <b-form-select-option :value="null" disabled>-- Select Year--</b-form-select-option>
                                  </template>
                                
                  
                     <b-form-select-option v-for="(year, index) in layer_years_Greece" :key="index+'yearGreece'" :value="year">{{year}}</b-form-select-option>
                       
                      
                    </b-form-select>
                  </div>
                 <!-- <div class="">
                    <b-form-select  v-model="selected_monthGreece" class="ml-auto" @change="ChangeSelectedGridGreece" >
                                
                                  <template #first>
                                    <b-form-select-option :value="null" disabled>-- Select Month--</b-form-select-option>
                                  </template>
                                
                       <b-form-select-option v-for="(month, index) in layer_months_Greece" :key="index+'monthGreece'" :value="month.value">{{month.name}}</b-form-select-option>
                     
                    </b-form-select>
                  </div>-->
                  
                  <div class=" text-center align-items-center mt-3">
                  <b>Month:</b>
                  <div class="w-100 px-4">
                   <vue-slider
                      v-model="selected_monthGreece"
                      :data="layer_months_Greece"
                      @change="ChangeSelectedGridGreece"
                    ></vue-slider>
                  </div>
                </div>
                  
                  <div class="text-dark px-2 mt-4">
                    <div class="text-center fw-bold" style="font-size:17px;"><b>Layer opacity:</b></div>
                    <vue-slider v-model="valueOpacity" class=""></vue-slider>
                   
                  </div>
                  <div class="">
                  <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="selected_point_greece"
                    :options="options_point_greece"
                    :aria-describedby="ariaDescribedby"
                  ></b-form-checkbox-group>
                </b-form-group>
                  </div>
                </div>
                <div v-if="selected_country==='Helsinki (FIN)'" class="text-dark">
                   <div class="py-2">
                  <b-form-select  v-model="selected_gridHelsinki" class="ml-auto" @change="ChangeSelectedGrid">
                              
                                <template #first>
                                  <b-form-select-option :value="null" disabled>-- Select Grid--</b-form-select-option>
                                </template>
                              
                    <!--<b-form-select-option :value="0" >Μαρκόπουλο</b-form-select-option> 
                    <b-form-select-option :value="1" >Πικροδάφνη</b-form-select-option> area_of_interest_data-->
                    <b-form-select-option value="teaser:tropos_flip_2019">Tropospheric</b-form-select-option>
                    <b-form-select-option value="teaser:surface_2019">Surface</b-form-select-option>
                    <!--<b-form-select-option value="average">Αverage</b-form-select-option>-->
                    
                  </b-form-select>
                  
                </div>
                  <div class="text-dark ">
                    <div class="text-center fw-bold" style="font-size:17px;">Layer opacity:</div>
                    <vue-slider v-model="valueOpacity" class=""></vue-slider>
                  </div>
                  <div class="">
                  
          
                 <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="selected_radio"
                    :options="options_radios"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                  ></b-form-checkbox-group>
                </b-form-group>
                
                </div>
                </div>
                <div v-if="selected_country==='Munich (GER)'"  class=" w-100 text-dark">
               <div  class="py-2">
                  <b-form-select  v-model="selected_aggregation" class="ml-auto" @change="ChangeSelectedAggregation">
                              
                                <template #first>
                                  <b-form-select-option :value="null" disabled>-- Select Aggregation--</b-form-select-option>
                                </template>
                              
                    <!--<b-form-select-option :value="0" >Μαρκόπουλο</b-form-select-option> 
                    <b-form-select-option :value="1" >Πικροδάφνη</b-form-select-option> area_of_interest_data-->
                    <b-form-select-option value="mean">Mean</b-form-select-option>
                    <b-form-select-option value="max">Max</b-form-select-option>
                    <!--<b-form-select-option value="average">Αverage</b-form-select-option>-->
                    
                  </b-form-select>
                  
                </div>
                <div class="w-100 text-center text-dark">
                  <b>Year:</b>
                  <div class="w-100 px-4">
                   <vue-slider
                      v-model="valueYear"
                      :marks="true"
                      :data="dataYear"
                      :included="true"
                    ></vue-slider>
                  </div>
                </div>
                </div>
              </div> 
                
                      </div>
                      
                    </div>
                  </div>
                </div>
           
        <l-map :zoom="zoom" @update:zoom="zoomUpdated" :center="center" @update:center="centerUpdated"  ref="myMap" :options="{zoomControl: false,attributionControl: false}" :style="{height:map_height}" style="transition: 0.5s;">
          

            <l-tile-layer :url="url"></l-tile-layer>
             
           <!-- <l-feature-group ref="featuresGreece">
              <l-popup > <span> {{name_of_point_greece}}</span></l-popup>
            </l-feature-group>-->
             <l-control position="bottomright" >
               <div v-if="selected_country==='Athens (GRC)' && selected_point_greece.length">
              <div class="card text-white p-2">
                <div class="d-flex align-items-center p-1">
                  <img src="img/sensor.png" style="width:30px;height:30px">
                  <h6 style="margin-bottom: 0rem;">Sensor node</h6>
                </div>
                <div class="d-flex align-items-center p-1">
                  <img src="img/air-monitoring.png" style="width:30px;height:30px">
                  <h6 style="margin-bottom: 0rem;">NOA air monitoring station</h6>
                </div>
                <div class="d-flex align-items-center p-1">
                  <img src="img/regulatory.png" style="width:30px;height:30px">
                  <h6 style="margin-bottom: 0rem;">Regulatory station</h6>
                </div>
              </div>
               </div>
            </l-control>
            <l-control-scale position="bottomright" :imperial="true" :metric="true"></l-control-scale>
           
            
             <!-- <l-control position="bottomleft" class="d-flex">
              <div v-if="lg_chart">
                <transition name="fade">
                <div v-if="show_chart_cities" style="background-color: rgba(208, 208, 208,0.9);">
                  
                <button @click="show_chart_cities=!show_chart_cities" type="button" class="position-absolute btn btn-primary btn-sm " style="z-index:1002;top: 0px;right: 0px;font-size: 17px; float: right; ">
                  &times;
                </button>
              
                 
                <div  style="width:100%">
                  <vue-highcharts :options="options_cities_data" ref="lineChartsCitiesData"></vue-highcharts>
               </div>
                
                </div>
                 </transition>
                 </div>
              </l-control>-->

             <l-control position="bottomleft" class="">
               
              <div  class=" " style="background-color: rgba(208, 208, 208,0.9);">
                <div v-if="show_chart && selected_country==='Munich (GER)' && polygonSelected && lg_chart">
                <button @click="show_chart=!show_chart" type="button" class="position-absolute btn btn-primary btn-sm " style="z-index:1002;top: 0px;right: 0px;font-size: 17px; float: right; ">
                  &times;
                </button>
                <div  style="width:100%">
                  <vue-highcharts :options="options" ref="lineCharts"></vue-highcharts>
               </div>
                </div>
                
                <!--<div class="text-center fw-bold" style="font-size:20px;"><p>Layer opacity:</p></div>
                <vue-slider v-model="valueOpacity" class="p-2"></vue-slider>
                <hr> options_cities_data-->
                
                <div v-if="selected_country==='Munich (GER)'" class="p-1">
                  <div class="text-center fw-bold" style="font-size:18px;">Aggregated Risk Index</div>
              <div class="d-flex flex-row">
              <b-icon  icon="caret-left-fill" aria-hidden="true" scale="1.4" style="color:#015201;  height:1.5em!important" ></b-icon>
                <div class="d-flex flex-row px-1" >
                <div class="box" style="background:#015201" ></div>
                <div class="box" style="background:#01AB01" ></div>
                <div class="box" style="background:#5DD700" ></div>
                <div class="box" style="background:#BBE900" ></div>
                <div class="box" style="background:#FAE100" ></div>
                <div class="box" style="background:#FFA500" ></div>
                <div class="box" style="background:#FF6600" ></div>
                <div class="box" style="background:#DF190B" ></div>
                <div class="box" style="background:#5B0101" ></div>
                
                </div>
                
              <b-icon  icon="caret-right-fill" aria-hidden="true" scale="1.4" style="color:#5B0101; height:1.5em!important "></b-icon>
              </div>
              <div class="d-flex flex-row text-center" style="padding-left: 1.4rem !important; padding-right: 1.9rem !important;">  
                  <div class="vl"></div>
                  <div class="vl"></div>
                  <div class="vl"></div>
                  <div class="vl"></div>
                  <div class="vl"></div>
                  <div class="vl"></div>
                  <div class="vl"></div>
                  <div class="vl"></div>
                  <div class="vl"></div>
                  <div class="vl"></div>
                  
                </div>
            
                <div class="d-flex flex-row text-center px-4">  
                  <p class="text" style="padding: 0px 16px 0px 0px;">0</p> 
                  <p class="text">1</p>
                  <p class="text">2</p>
                  <p class="text">3</p>
                  <p class="text">4</p>
                  <p class="text">5</p>
                  <p class="text">6</p>
                  <p class="text">7</p>
                  <p class="text">8</p>
                  <p class="text">8+</p>
                  
                  
                </div>
                </div>
                <div v-if="selected_country==='Athens (GRC)' && selected_gridGreece==='no2'" class="p-1">
                  <div v-if="show_athens_urban_center" >
                  <h6 class="text-center mb-2" ><b> Athens Urban Center:</b><span style="color:blue"> {{parseFloat(athens_urban_center_data[selected_gridGreece][parseInt(selected_monthGreece)-1]).toFixed(2)}}% </span>
                  <span style="font-size:13px"> of population <br> of the Urban Center (tot.pop. 2.772.905) subjected to mean daily NO<sub>2</sub> levels above 25 μgm<sup>-3</sup> <br>(WHO air quality guidelines, 2021)</span>
                  </h6>
                  <hr>
                  </div>
                  <h6 class="text-center"><b>Mean monthly population exposure to NO<sub>2</sub></b> </h6>
              <div class="d-flex justify-content-center flex-row">
             <!-- <b-icon  icon="caret-left-fill" aria-hidden="true" scale="2" style="color:#015201;  height:1.5em!important" ></b-icon>-->
                <div class="d-flex flex-row px-1" >
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(1,5,125);font-size: 10px;color:white" >0-50</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(0,0,242);font-size: 10px;color:white" >51-100</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(5,105,255);font-size: 10px;color:white" >101-250</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(0,185,252);font-size: 10px;color:white" >251-500</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(56,255,179);font-size: 10px;color:white" >501-800</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(179,255,56);font-size: 10px;color:white" >801-1000</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(255,217,0);font-size: 10px;color:white" >1001-2000</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(255,105,5);font-size: 10px;color:white" >2001-3000</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(240,0,0);font-size: 10px;color:white" >3001-5000</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(133,4,0);font-size: 10px;color:white" >5001-10000</div>
                
                </div>
                
            <!--  <b-icon  icon="caret-right-fill" aria-hidden="true" scale="2" style="color:#5B0101; height:1.5em!important "></b-icon>-->
              </div>
                  <div class="d-flex justify-content-center">
                   <h6>μg*m<sup>-3</sup> x number of residents</h6>
                 </div>
            
                </div>
                <div v-if="selected_country==='Athens (GRC)' && selected_gridGreece==='pm25'" class="p-1">
                  <div v-if="show_athens_urban_center" >
                  <h6 class="text-center mb-2" v-if="show_athens_urban_center"><b> Athens Urban Center:</b> <span style="color:blue">{{parseFloat(athens_urban_center_data[selected_gridGreece][parseInt(selected_monthGreece)-1]).toFixed(2)}}%</span> 
                  <span style="font-size:13px"> of population <br> of the Urban Center (tot.pop. 2.772.905) subjected to mean daily PM<sub>2.5</sub> levels above 15 μgm<sup>-3</sup> <br>(WHO air quality guidelines, 2021)
                  <!-- <br> -->
                 
                  </span>
                  
                  </h6>
                   
                  <hr>
                  </div>
                  <!-- <div class="text-center" style="font-size:13px" > Hyperlink for WHO AQ guidelines: <a class="text-center" style="color:red!important" href="https://www.who.int/news-room/feature-stories/detail/what-are-the-who-air-quality-guidelines" target="_blank">here</a></div> -->

                  <h6 class="text-center"><b> Mean monthly population exposure to PM<sub>2.5</sub></b> </h6>
              <div class="d-flex justify-content-center flex-row">
             <!-- <b-icon  icon="caret-left-fill" aria-hidden="true" scale="2" style="color:#015201;  height:1.5em!important" ></b-icon>-->
                <div class="d-flex flex-row px-1" >
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(1,5,125);font-size: 10px;color:white" >0-100</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(0,0,242);font-size: 10px;color:white" >101-250</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(5,105,255);font-size: 10px;color:white" >251-500</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(0,185,252);font-size: 10px;color:white" >501-750</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(56,255,179);font-size: 10px;color:white" >751-1000</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(179,255,56);font-size: 10px;color:white" >1001-2000</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(255,217,0);font-size: 10px;color:white" >2001-3500</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(255,105,5);font-size: 10px;color:white" >3501-5000</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(240,0,0);font-size: 10px;color:white" >5001-7000</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(133,4,0);font-size: 10px;color:white" >7001-10000</div>
                
                </div>
                
            <!--  <b-icon  icon="caret-right-fill" aria-hidden="true" scale="2" style="color:#5B0101; height:1.5em!important "></b-icon>-->
              </div>
                 <div class="d-flex justify-content-center">
                   <h6>μg*m<sup>-3</sup> x number of residents</h6>
                   
                 </div>
            
                </div>
                <div v-if="selected_country==='Athens (GRC)' && selected_gridGreece==='aqi'" class="p-1">
                  
                  <h6 class="text-center"><b>European Air Quality Index</b></h6>
              <div class="d-flex justify-content-center flex-row">
             <!-- <b-icon  icon="caret-left-fill" aria-hidden="true" scale="2" style="color:#015201;  height:1.5em!important" ></b-icon>-->
                <div class="d-flex flex-row px-1" >
                <div class="box3 d-flex align-items-center justify-content-center" style="background:rgb(80,240,230);font-size: 11px;color:white" >Good</div>
                <div class="box3 d-flex align-items-center justify-content-center" style="background:rgb(80,204,170);font-size: 11px;color:white" >Fair</div>
                <div class="box3 d-flex align-items-center justify-content-center" style="background:rgb(240,230,65);font-size: 11px;color:black" >Moderate</div>
                <div class="box3 d-flex align-items-center justify-content-center" style="background:rgb(255,80,80);font-size: 11px;color:white" >Poor</div>
                <div class="box3 d-flex align-items-center justify-content-center" style="background:rgb(150,0,50);font-size: 11px;color:white" >Very poor</div>
                <div class="box3 d-flex align-items-center justify-content-center" style="background:rgb(125,33,129);font-size: 11px;color:white" >Extremely poor</div>
            
                </div>
                
            <!--  <b-icon  icon="caret-right-fill" aria-hidden="true" scale="2" style="color:#5B0101; height:1.5em!important "></b-icon>-->
              </div>
                 <div class=" text-center">
                   <h6>(legend explained <a class="text-danger" href="https://airindex.eea.europa.eu/Map/AQI/Viewer/" target="_blank">here</a>)</h6>
                 </div>
            
                </div>
                    <div v-if="selected_country==='Helsinki (FIN)' && selected_gridHelsinki==='teaser:surface_2019'" class="p-1">
                      <h6 class="text-center"><b> Mean seasonal (Apr.-Sep. 2019) NO<sub>2</sub> levels</b> </h6>
                      
              <div class="d-flex justify-content-center flex-row">
             <!-- <b-icon  icon="caret-left-fill" aria-hidden="true" scale="2" style="color:#015201;  height:1.5em!important" ></b-icon>-->
                <div class="d-flex flex-row px-1" >
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(49,129,183);font-size: 15px;" >5.9</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(172,220,167);font-size: 15px;" >7.5</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(255,255,196);font-size: 15px;" >9.1</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(251,177,107);font-size: 15px;" >10.7</div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(213,48,40);font-size: 15px;" >12.3</div>
                
                </div>
                
            <!--  <b-icon  icon="caret-right-fill" aria-hidden="true" scale="2" style="color:#5B0101; height:1.5em!important "></b-icon>-->
              </div>
                  <div class="d-flex justify-content-center">
                   <p>µg*m<sup>-3</sup></p>
                 </div>
            
                </div>
                <!--<div class="text-center" style="font-size:15px;"><p>(μg/m<sup>3</sup>)</p></div>-->
                 <div v-if="selected_country==='Helsinki (FIN)' && selected_gridHelsinki==='teaser:tropos_flip_2019'" class="p-1">
                   <h6 class="text-center"><b> Mean seasonal (Apr.-Sep. 2019) NO<sub>2</sub> levels</b> </h6>
              <div class="d-flex justify-content-center flex-row">
             <!-- <b-icon  icon="caret-left-fill" aria-hidden="true" scale="2" style="color:#015201;  height:1.5em!important" ></b-icon>-->
                <div class="d-flex flex-row px-1" >
                <div class="box2 d-flex align-items-center justify-content-center" style="background-color:rgb(49,129,183);font-size: 15px;" >2.4e<sup>15</sup></div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(172,220,167);font-size: 15px;" >3.4e<sup>15</sup></div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(255,255,196);font-size: 15px;" >4.5e<sup>15</sup></div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(251,177,107);font-size: 15px;" >5.5e<sup>15</sup></div>
                <div class="box2 d-flex align-items-center justify-content-center" style="background:rgb(213,48,40);font-size: 15px;" >6.5e<sup>15</sup></div>
                
                </div>
                
            <!--  <b-icon  icon="caret-right-fill" aria-hidden="true" scale="2" style="color:#5B0101; height:1.5em!important "></b-icon>-->
              </div>
                 <div class="d-flex justify-content-center">
                   <h6> molec.*cm<sup>-2</sup></h6>
                   
                 </div>
            
                </div>
              </div>
              
            </l-control>
            <l-control position="bottomleft" v-if="selected_country==='Bari (IT)'">
              
              <div class="card">
              <div class="card-body">
                <div v-for="(layer, index) in bari_data" :key="index+'bari_checkbox'">
                <div class="w-100 d-flex  justify-content-between align-items-center">
                <b-form-checkbox
                  v-model="visible_bari_layer[index]"
                  name="checkbox-1"
                

                  class="text-light"
                  style="font-size:14px"
                 
                >
                  <span v-html="layer.name"></span>
                </b-form-checkbox>
                <b-button v-b-toggle="'collapse-'+index" variant="warning" size="sm"><b-icon icon="gear-fill" aria-hidden="true" animation="spin"></b-icon></b-button>
                
                </div>
                 <b-collapse :id="'collapse-'+index" class="mt-2">
                  
                    <div class=" w-100 text-center">
                      <vue-slider  v-model="valueOpacity_bari[index]" class="w-75 px-2"></vue-slider>
                      <legendBari :bari_data="layer"></legendBari>
                
                        
                   
                    </div>
                 
                </b-collapse>
                
                <hr class="mt-1 mb-1" v-if="index!==bari_data.length-1">
                </div>
              </div>
            </div>
            </l-control>
            <!--value:polygon['d'+valueYear+selected_aggregation]-->
            <div v-if="selected_country==='Munich (GER)'">
              <div v-for="(polygon,index) in all_data" :key="index">
               <l-geo-json :geojson="{type:'FeatureCollection',features:[{type:'Feature',geometry:JSON.parse(polygon.st_asgeojson),properties:{mean:polygon['d'+valueYear+'mean'],max:polygon['d'+valueYear+'max'],id:polygon.plz_id,polygon:polygon}}]}"
               :optionsStyle="colorsFunction(polygon)"
               :options="optionsGeoJson"></l-geo-json>
              </div>
            </div>
            <!--colorsFunction(polygon['d'+valueYear+selected_aggregation])-->
              <div v-for="(marker,index) in marker_countries" :key='index+"100"'>
              <l-marker :lat-lng="JSON.parse(marker).center"  @click="CountryClick(JSON.parse(marker))"> 
                <l-icon
                  :icon-anchor="[13, 13]"   
                >
                 
                  <b-icon v-if="selected_country!==JSON.parse(marker).name" icon="circle-fill" animation="throb" font-scale="1" :variant="JSON.parse(marker).color"></b-icon>
                  <b-icon v-if="selected_country===JSON.parse(marker).name && zoom_lvl<7" icon="circle-fill" animation="throb" font-scale="1" variant="primary"></b-icon>
                  <b-icon icon="circle-fill" font-scale="0" variant="primary" style="opacity:0"></b-icon>
                  
                </l-icon>
                <l-tooltip>{{JSON.parse(marker).name}}</l-tooltip>
              </l-marker>
              </div>
              <div v-if="selected_country==='Helsinki (FIN)'">
              <div v-for="(ind,index) in json_Helsinki" :key="index+'12'">
                <div v-if="selected_radio.indexOf(ind.name)>-1">
                  <div v-for="(markers,index) in ind.features" :key='index'>
                  <l-marker :lat-lng="[markers.properties.Latitude,markers.properties.Longitude]" >
                         <l-icon
                    :icon-size="[40, 40]"
                   class-name="someExtraClass"
                    :icon-url="iconMarker(markers,ind.name)"
                >
    <!-- :icon-url="'/markers/'+ind.name+'.png'"-->
                </l-icon>
                        <l-tooltip v-if="ind.name==='E-PRTR_Helsinki_NOx_emissions_to_air_2019'">{{markers.properties.Facility}} <br> <p style="font-size:12px">{{markers.properties.Main_activity}}</p> <strong>{{markers.properties.NOx_emissions_tons.toFixed(1)}} tonnes per year </strong>(emission rate for 2019)</l-tooltip><!--Main_activity-->
                        <l-tooltip v-if="ind.name==='AQstations_Helsinki_NO2_mean_20190401-20190930'">{{markers.properties.Station_name}} <br>{{markers.properties.Station_classification}} station<br><p style="font-size:14px"><strong> {{markers.properties.NO2_TROPOMIsampling_ug_m3.toFixed(1)}} µg*m</strong><sup>-3</sup>(mean Apr-Sept 2019/ TROPOMI sampling)</p><p style="font-size:14px"><strong> {{markers.properties.NO2_ug_m3.toFixed(1)}} µg*m<sup>-3</sup></strong>(2019 mean annual)</p> </l-tooltip>
                  </l-marker>
                  </div>
                </div>
              </div>
              </div>
              <div v-if="selected_country==='Athens (GRC)'">
              <div v-for="(ind,index) in json_Greece" :key="index+'Greece'">
                <div v-if="selected_point_greece.indexOf('point Greece')>-1">
                  <div v-for="(markers,index) in ind.features" :key='index'>
                  <l-marker :lat-lng="[markers.properties.Latitude,markers.properties.Longitude]" @click='clickPointGreece(markers)'>
                    <l-icon
                      :icon-size="[40, 40]"
                      :icon-url="'img/'+markers.properties.marker+'.png'"
                    />
                  <l-tooltip>{{markers.properties.station_fullname}}</l-tooltip>
                  <l-popup>{{markers.properties.station_fullname}}</l-popup>  
                  </l-marker>
                  </div>
                </div>
              </div>

              <l-geo-json :geojson="athens_urban_center"
              :optionsStyle="{'color':'#e60092','fillOpacity':0}"
              :options="optionsGeoJson_athens_urban_center"
              ></l-geo-json>

              </div>
              <!--v-if="selected_country==='Helsinki'"-->
              <l-wms-tile-layer 
                ref="wms_layer"
                :base-url="wmsLayer.url"
                :layers="wmsLayer.layers"
                :visible="wmsLayer.visible"
                :name="wmsLayer.name"
                
                :transparent="true"
                format="image/png"
                layer-type="base"
                :opacity='valueOpacity/100'>
              </l-wms-tile-layer>
              <div v-if="bari_data">
                <l-wms-tile-layer v-if="selected_country==='Bari (IT)'"
                  
                  :base-url="wmsLayer.url"
                  :layers="'teaser:bari_boundaries'"
                  :transparent="true"
                  format="image/png"
                  layer-type="base"
                >
                </l-wms-tile-layer>
              <div v-for="(layer,index) in bari_data" :key="index+'bari'">
                  <l-wms-tile-layer v-if="selected_country==='Bari (IT)'"
                  ref="wms_layer_bari"
                  :base-url="wmsLayer.url"
                  :layers="layer.value"
                  :visible="visible_bari_layer[index]"
                  :transparent="true"
                  format="image/png"
                  layer-type="base"
                  :opacity='valueOpacity_bari[index]/100'>
                </l-wms-tile-layer>
              </div>
              </div>
             <!-- <l-wms-tile-layer v-if="selected_country==='Greece'"
                ref="wms_layer_greece"
                :base-url="wmsLayer.url"
                :layers="'teaser:pm25_athens'"
                :visible="wmsLayer.visible"
                :transparent="true"
                format="image/png"
                layer-type="base"
                :opacity='valueOpacity/100'>
              </l-wms-tile-layer>-->
              
         </l-map>
           <div v-if="show_chart_greece && selected_country==='Athens (GRC)'">
             <button @click="hideChartGreece" type="button" class="position-absolute btn btn-primary btn-sm " style="z-index:1002;top: 71vh;right: 0px;font-size: 17px; float: right; ">
                  &times;
              </button>
            <div class="container mb-5">
                <div class="d-flex pt-5 ">
                  <b-form-select  v-model="selected_year" class="ml-auto shadow" @change="ChangeSelectedYear">
                              
                                <template #first>
                                  <b-form-select-option :value="null" disabled>-- Select Year--</b-form-select-option>
                                </template>
                              
                    
                    <b-form-select-option v-for="(year,index) in years_array" :key="index+'year'" :value="year.YearOfData" >{{year.YearOfData}}</b-form-select-option>
                    
                    
                  </b-form-select>
                  <b-form-select  v-model="selected_month" class="ml-auto shadow" @change="ChangeSelectedMonth">
                              
                                <template #first>
                                  <b-form-select-option :value="null" disabled>-- Select Month--</b-form-select-option>
                                </template>
                              
                   
                    <b-form-select-option v-for="(months,index) in months_array" :key="index+'year'" :value="months.MonthName" >{{months.MonthName}}</b-form-select-option>
                
                    
                  </b-form-select>
                </div>
            </div>
                <div  style="width:98%;" v-if="selected_month">
                  
                  <vue-highcharts :options="options_greece" ref="lineChartsGreeceNO2"></vue-highcharts>
                  <div class="text-center font-weight-bold">(Hours are in UTC)</div>
                  <hr style="background-color:orange">
                  <vue-highcharts :options="options_greece_pm25" ref="lineChartsGreecePM25"></vue-highcharts>
                   <div class="text-center font-weight-bold">(Hours are in UTC)</div>
                   <hr style="background-color:orange">
                  <vue-highcharts :options="options_greece_aqi" ref="lineChartsGreeceAQI"></vue-highcharts>
                   <div class="text-center font-weight-bold">(Hours are in UTC)</div>
               </div>
          
          </div>
          <div v-if="lg_chart || sm_chart"><!--sm_chart-->
                
                <div v-if="show_chart_cities" style="background-color: rgba(208, 208, 208,0.9);">
                  
                <button @click="hideChartCities" type="button" class="position-absolute btn btn-primary btn-sm " style="z-index:1002;top: 69vh;right: 0px;font-size: 17px; float: right; ">
                  &times;
                </button>
              
                 
                <div  style="width:98%;">
                  <highcharts class="p-5" style="height:60vh" :options="options_cities_data" ref="lineChartsCitiesData"></highcharts>
               </div>
                
                </div>

                <div v-if="show_chart && selected_country==='Munich (GER)' && polygonSelected">
                <button @click="hideChartMunich" type="button" class="position-absolute btn btn-primary btn-sm " style="z-index:1002;top: 75vh;right: 0px;font-size: 17px; float: right; ">
                  &times;
                </button>
                <div  style="width:100%">
                  <vue-highcharts :options="options" ref="lineCharts"></vue-highcharts>
               </div>
                </div>
                 
          </div>
         <div class="w-100" style="z-index:1000;background: rgb(231 231 231 / 92%);" >
            <div class="d-flex justify-content-center p-2">
                            <div class="">
                                <img src="img/eshape.png" width="50"><img src="img/europe.jpg" width="50">
                            </div>
                            <div class=" align-self-center text-black">
                                The e-shape project has received funding from the European Union’s Horizon 2020
                               
                                 research and innovation programme under grant agreement 820852       
                            </div>
                </div>
           </div>
            <div v-if="selected_country!==null" class="position-absolute " style="z-index:1000;top: 20px; right: 80px;min-width:200px;max-width:380px;" v-draggable="{ handle: 'strong' }">
           <div class="accordion" role="tablist" >
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <div class="d-flex">
                <b-button block v-b-toggle.accordion-1 variant="info">Info<b-icon icon="info-circle-fill" scale="0.9" variant="light"></b-icon> </b-button>
                <strong
                  ><div class="bg-dark w-100 p-2 text-center">
                    <b-icon
                      class="revue-draggable"
                      icon="arrows-move"
                      scale="1.3"
                      variant="light"
                    ></b-icon></div
                ></strong>
                </div>
              </b-card-header>
              <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                <b-card-body style="max-height: 400px;overflow-x: hidden;overflow-y: scroll">
                  <b-card-text class="text-light" style=" text-align: justify;">
                    <div v-html="info_Helsinki" v-if="selected_country==='Helsinki (FIN)'"></div>
                    <div v-html="info_Munich" v-if="selected_country==='Munich (GER)'"></div>
                    <div v-html="info_Athens" v-if="selected_country==='Athens (GRC)'"></div>
                    <div v-html="info_Bari" v-if="selected_country==='Bari (IT)'"></div>
                    
                    <div v-html="info_Megacities" v-if="selected_country!=='Helsinki (FIN)' && selected_country!=='Munich (GER)' && selected_country!=='Athens (GRC)'  && selected_country!=='Bari (IT)'"></div>

                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
           </div>
            </div>
       
      
        </div>

           
    </div>
 
  
</div>
</div>


</template>

<script>
import axios from 'axios'
//import TopBar from './TopBar.vue'
import { LMap, LTileLayer, LMarker,LGeoJson,LControl,LTooltip,LControlScale,LWMSTileLayer,LPopup,LIcon  } from 'vue2-leaflet';
import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min.js'
import 'vue-slider-component/dist-css/vue-slider-component.css'

import 'vue-slider-component/theme/default.css'
//import Vue2LeafletHeatmap from './Vue2LeafletHeatmap.vue'
//import { Control } from 'leaflet';
//import VueHighcharts from 'vue2-highcharts'

import json_eprtr_all from '../../public/json/E-PRTR_Helsinki_NOx_emissions_to_air_2019.json'
import json_aqstations_all from '../../public/json/AQstations_Helsinki_NO2_mean_20190401-20190930.json'
import json_greece from '../../public/json/point_Greece.json'
import layer_json_greece from '../../public/json/layer_Greece.json'
import json_cities_data from '../../public/json/cities_Data.json'
import { info_function } from "../info/info";
import athens_urban from "../../public/json/athens_urban_center.json"
import athens_urban_data from "../../public/json/athens_urban_center_data.json"
//import test from '../../public/json/data.json'
import lat_lng_countries from '../../public/json/lat_lng_Countries.json'
import bari_data from '../../public/json/bari_data.json'
import legendBari from '../components/legendBari.vue'
export default {
  name:'FirstIndex',
    components:{
        LPopup,
        LControlScale,
        LTooltip,
        LControl,

       // TopBar,
        LMap,
        LTileLayer,
        LMarker,
        VueSlider,
        LGeoJson,
        'l-wms-tile-layer': LWMSTileLayer,
        
        //VueHighcharts,
        LIcon ,
        legendBari
        
       
    },

    data(){
      return{
        bari_data:bari_data,
        visible_bari_layer:new Array(6).fill(false),
        valueOpacity_bari:new Array(6).fill(100),
        show_athens_urban_center:false,
        athens_urban_center:athens_urban,
        athens_urban_center_data:athens_urban_data,
        layer_years_Greece:layer_json_greece.years,
        layer_months_Greece:layer_json_greece.months,
        info_Helsinki:info_function.info_Helsinki(),
        info_Munich:info_function.info_Munich(),
        info_Megacities:info_function.info_Megacities(),
        info_Athens:info_function.info_Athens(),
        info_Bari:info_function.info_Bari(),
        lg_chart:true,
        sm_chart:false,
        show_chart_cities:null,
        cities_data:json_cities_data,
        selected_yearGreece:2019,
        selected_monthGreece:'01',
        map_height:'82vh',
        months_array:[],
        years_array:[],
        selected_year:null,
        selected_month:null,
        show_settings:true,
        json_Greece:[json_greece],
        json_Helsinki:[],
        json_eprtr:json_eprtr_all,
        json_aqstations:json_aqstations_all,
        selected_point_greece:[],
        options_point_greece: [
          { text: 'Monitoring Stations', value: 'point Greece' },
         
        ],
        selected_radio:[],
        options_radios: [
          { text: 'Industrial Sites', value: 'E-PRTR_Helsinki_NOx_emissions_to_air_2019' },
          { text: 'AQ Stations', value: 'AQstations_Helsinki_NO2_mean_20190401-20190930' },
          
        ],
        wmsLayer: {
          //url: 'http://185.4.134.30:8080/geoserver/teaser/wms',
          url: 'https://mapserver.beyond-eocenter.eu/geoserver/teaser/wms',
          name: 'teaser helsinki',
          visible: true,
          format: 'image/png',
          layers: '',
          transparent: true,
         
  
       },
        selected_country:null,
        selected_gridHelsinki:'teaser:tropos_flip_2019',
        selected_gridGreece:'pm25',
        width_screen:null,
        marker_countries:lat_lng_countries,
        show_chart:false,
        show_chart_greece:false,
        name_of_point_greece:null,
        options_greece:{
         
          chart: {
            backgroundColor: 'transparent',
            type: 'spline',
            
          },
          plotOptions: {
              series: {
                  shadow: true,
                 
              }
          },
          tooltip: {
                split: true,
          },
          credits: {
              enabled: false
          },
          title: {
            text: 'NO'+'<sub>2</sub>',
            useHTML : true,
          }, 
          
          xAxis: {
            
          },
          legend: {
            useHTML: true,
           },
           yAxis: [{
            title: {
              text: 'Hourly concentration (μgm<sup>-3</sup>)',
              useHTML: true
            }
           }],
         series: []
        },
         options_greece_pm25:{
            
          chart: {
            backgroundColor: 'transparent',
            type: 'spline',
            
          },
          plotOptions: {
              series: {
                  shadow: true
              }
          },
          credits: {
              enabled: false
          },
          title: {
            text: 'PM'+'<sub>2.5</sub>',
            useHTML : true,
          }, 
          tooltip: {
                split: true,
          },
          xAxis: {
             
          },
          legend: {
            useHTML: true,
           },
           yAxis: [{
              
            title: {
              text: 'Hourly concentration (μgm<sup>-3</sup>)',
              useHTML: true
            },
           
           }],
         series: []
        },
        
        options_greece_aqi:{
            
            chart: {
              backgroundColor: 'transparent',
              type: 'spline',
              
            },
            plotOptions: {
                series: {
                    shadow: true
                }
            },
            credits: {
                enabled: false
            },
            title: {
              text: 'AQI',
              useHTML : true,
            }, 
            tooltip: {
                useHTML: true,
                split: true,
                
                formatter: function () {
                  // The first returned item is the header, subsequent items are the
                  // points
                  return ['<b>' + this.x + '</b>'].concat(
                      this.points ?
                          this.points.map(function (point) {
                            if(point.series.userOptions.dueTo[point.point.index]===null){
                              return point.series.name + ': <b>' + point.y+'</b>(μgm<sup>-3</sup>)';
                              
                            }else{
                              return point.series.name + ': <b>' + point.y+'</b>(μgm<sup>-3</sup>)' + '<br><span style="color:blue"> due to ('+point.series.userOptions.dueTo[point.point.index]+')</span>';
                            }
                              
                          }) : []
                  );
              },
               
            },
            xAxis: {
             
            },
            legend: {
              useHTML: true,
             },
             yAxis: [{
                
              title: {
                text: 'Hourly concentration (μgm<sup>-3</sup>)',
                useHTML: true
              },
             
             }],
           series: []
          },
        options_cities_data:{
         
          chart: {
            backgroundColor: 'transparent',
            type: 'spline',
            scrollablePlotArea: {
              minWidth: 3000,
              scrollPositionX: 1
            }
            
          },
          
          
          plotOptions: {
              series: {
                  shadow: true,
                  marker: {
                        enabled: false
                    }
                 
              },
               
          },
          credits: {
              enabled: false
          },
          title: {
            text: 'Aggregated Risk Index',
            useHTML : true,
          }, 
          
         
          legend: {
            useHTML: true,
           },
          xAxis: {
             
              labels: {
                  overflow: 'justify'
              }
          },
          yAxis: {
              stackLabels: {
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  borderWidth: 1,
      
              }
          },
           /*
           
           scrollbar: {
              barBackgroundColor: 'gray',
              barBorderRadius: 7,
              barBorderWidth: 0,
              buttonBackgroundColor: 'gray',
              buttonBorderWidth: 0,
              buttonArrowColor: 'yellow',
              buttonBorderRadius: 7,
              rifleColor: 'yellow',
              trackBackgroundColor: 'white',
              trackBorderWidth: 1,
              trackBorderColor: 'silver',
              trackBorderRadius: 7
          },
           rangeSelector: {
                enabled: false
            },*/
               
          
        },
        options: {
          chart: {
            backgroundColor: 'rgba(208, 208, 208, 0.2)',
            type: 'spline'
          },
          plotOptions: {
              series: {
                  shadow: true
              }
          },
          credits: {
              enabled: false
          },
          title: {
            text: 'Aggregated Risk Index'
          }, 
          subtitle:{
            text:'new'
          },
          xAxis: {
            categories: [2016,2017,2018,2019]
          },
           yAxis: [{
              labels: {
              format: '{value}',
                style: {
                    color: 'green'
              }
            },
             title: {
            text: 'Mean',
           style: {
                    color: 'green'
              }
            }
           },{
              title: {
            text: 'Max',
            },opposite: true
           }],
         series: []
        },
      all_data:null,
      all_data_germany:null,
      polygonSelected:null,
      valueYear:2019,
      dataYear:[2016,2017,2018,2019],
      valueYeartest:'1',
      selected_aggregation:'mean',
      valueOpacity:100,
      color: '#dc3545',
      //url:'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      //url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      //url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
      url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
      
      zoom: 2,
      zoom_lvl:null,
      center: [ 33.4808302785626, 24.4775390625],
      checked1:false,
      max:1440,
      value: '0',
      data:[],
      marks:{},
      output_of_box:null,
      //baseUrl: 'http://idcomvps01.ddns.net:8080/geoserver/pm25/wms',
      baseUrl: 'https://mapserver.beyond-eocenter.eu/geoserver/pm25/wms',


      
    }
    },
    computed: {
    
      optionsGeoJson() {
        return {
          onEachFeature: this.onEachFeatureFunction,
      
        };
      },
      optionsGeoJson_athens_urban_center() {
        return {
          onEachFeature: this.onEachFeatureFunction_athens_urban_center,
      
        };
      },
      onEachFeatureFunction_athens_urban_center(){
        var v = this;
        return (feature, layer) => {
        layer.on('mouseover', function (e) {
          //console.log('mouseover')
          v.show_athens_urban_center=true
        })
        layer.on('mouseout', function (e) {
          //console.log('mouseleave')closeNav
          v.show_athens_urban_center=false
        })
        }
      },
      onEachFeatureFunction(){
        var v = this;
        return (feature, layer) => {
          var id_name=feature.properties.id.split(' ')
          //console.log(v.selected_aggregation)
          
          if(v.selected_aggregation==='average'){
            //console.log('here')
            let mean=parseFloat(feature.properties.mean)
            let max=parseFloat(feature.properties.max)
            layer.bindTooltip(
              "<div>" +id_name[1] +':'+ (mean+max)/2 +
                "</div>",
              { permanent: false, sticky: true }
            );
          }else{
            layer.bindTooltip(
              "<div>" +id_name[1] +':'+ feature.properties[v.selected_aggregation] +
                "</div>",
              { permanent: false, sticky: true }
            );
          }




          layer.on('click', function (e) {
           // e.sourceTarget.setStyle({ color: "#C6212D", fillOpacity: 0 });
            v.polygonSelected=e.target.feature.properties.polygon
            v.show_chart=true
            if(window.innerWidth<1024){
             v.map_height='60vh'
            }
           // console.log(layer)
            //v.options.series
            v.$nextTick(() => {
            
            var polygon_data=e.target.feature.properties.polygon
            var mean=[]
            var max=[]
            var average=[]
            //console.log(polygon_data)
              


            for(var i in polygon_data){
              
              var value=parseFloat(polygon_data[i])
              if(i.includes('mean')){
                mean.push(value)
              }
              if(i.includes('max')){
                max.push(value)
              }

              
              
              
            }
            for(var k=0; k<mean.length; k++){
              var average_values= (mean[k]+max[k])/2
              average.push(average_values)
            }
            //console.log(mean)

            var lineCharts=v.$refs.lineCharts
            
            lineCharts.removeSeries()
            
            var id_name=v.polygonSelected.plz_id.split(' ')
            //console.log(id_name)
            lineCharts.chart.setTitle(null,{ text: id_name[1] })
            lineCharts.addSeries({
              name: 'Mean',
              yAxis: 0,
              color:'green',
              data: mean,
              
            })
             lineCharts.addSeries({
              name: 'Max',
              yAxis: 1,
              color:'black',
              data: max
            })
            /*
             lineCharts.addSeries({
              name: 'Average',
              data: average
            })*/
           
            })
            
           
          })
         
        }
      },
      

    },
    async created(){
      //const response = await fetch('json/athens_urban_center.geojson');
      //console.log(JSON.parse(test))
      console.log(JSON.parse(this.marker_countries[0]).canter)
      
      if(window.innerWidth<1024){
        this.show_settings=false
        
       this.sm_chart=true
       this.lg_chart=false
      }
      this.width_screen=window.screen.width/1.2+'px'
      //console.log(this.width_screen)
    //var all_object=await axios.get("http://hsaq.ddns.net/backend/munich.php")
    var all_object=await axios.get("https://hsaq-eshape.eu/backend/munich.php")
    //var all_object=await axios.get("/backend/teaser.php")
    //console.log(all_object)
    this.all_data_germany=all_object.data
   // console.log(this.json_eprtr)
    //console.log(json_aqstations)
    this.json_Helsinki=[json_eprtr_all,json_aqstations_all]
    //console.log(this.json_Helsinki)
    //console.log(this.json_Greece)
    //console.log(new Date().valueOf())
    //console.log(new Date('2019-01-06 00:00:00').valueOf())
    //console.log(new Date('2019-01-06 00:00:00'))
    //console.log(new Date().getTimezoneOffset())
   
  },

  methods: {
    hideChartMunich(){
      this.show_chart=!this.show_chart
      this.map_height='82vh'
    },
    hideChartCities(){
      this.show_chart_cities=!this.show_chart_cities
      this.map_height='82vh'
      setTimeout(() => {this.$refs.myMap.mapObject.invalidateSize(); }, 700);
    
    },
    changeMonthGreece(){
     // console.log('here')
    },
    hideChartGreece(){
      this.show_chart_greece=!this.show_chart_greece
      this.map_height='82vh'
      setTimeout(() => {this.$refs.myMap.mapObject.invalidateSize(); }, 700);
    },
    ChangeSelectedYear(){
      var v=this
      axios.get("https://hsaq-eshape.eu/backend/athens_distict_months.php",{params:{station_Name: this.name_of_point_greece,year_Data:this.selected_year}}).then(res => {
        v.months_array=res.data
        v.selected_month=res.data[0].MonthName
        v.ChangeSelectedMonth()
      })
    },
    ChangeSelectedMonth(){
      var v=this
      axios.get("https://hsaq-eshape.eu/backend/athens_station_data.php",{params:{station_Name: this.name_of_point_greece,year_Data:this.selected_year,month_Data:this.selected_month}}).then(res => {
        console.log("1",res.data)
        var lineChartsNO2=v.$refs.lineChartsGreeceNO2
        var lineChartsPM25=v.$refs.lineChartsGreecePM25
        var lineChartsAQI=v.$refs.lineChartsGreeceAQI
        var data=res.data
        var xaxis=[]
        var NO2_meas=[]
        var NO2_model=[]
        var PM25_meas=[]
        var PM25_model=[]
        var AQI_model=[]
        var AQI_meas=[]
        var AQI_model_pollutant=[]
        var AQI_meas_pollutant=[]
        //var unix
        
        for(var qq=0; qq<data.length; qq++){
          //unix=new Date(data[qq].Datetime).valueOf()
          //xaxis.push(parseFloat(data[qq].UnixTime))
          xaxis.push(data[qq].Datetime)
          NO2_meas.push(parseFloat(data[qq].NO2_meas))
          NO2_model.push(parseFloat(data[qq].NO2_model))
          PM25_meas.push(parseFloat(data[qq].PM25_meas))
          PM25_model.push(parseFloat(data[qq].PM25_model))
          AQI_model.push(parseFloat(data[qq].AQI_model))
          AQI_meas.push(parseFloat(data[qq].AQI_meas))
          console.log(data[qq].AQI_model_pollutant)
          if(data[qq].AQI_model_pollutant){
            data[qq].AQI_model_pollutant=data[qq].AQI_model_pollutant.replace('PM10','PM<sub>10</sub>')
            data[qq].AQI_model_pollutant=data[qq].AQI_model_pollutant.replace('PM25','PM<sub>2.5</sub>')
            data[qq].AQI_model_pollutant=data[qq].AQI_model_pollutant.replace('O3','O<sub>3</sub>')
          }
          if(data[qq].AQI_meas_pollutant){
            data[qq].AQI_meas_pollutant=data[qq].AQI_meas_pollutant.replace('PM10','PM<sub>10</sub>')
            data[qq].AQI_meas_pollutant=data[qq].AQI_meas_pollutant.replace('PM25','PM<sub>2.5</sub>')
            data[qq].AQI_meas_pollutant=data[qq].AQI_meas_pollutant.replace('O3','O<sub>3</sub>')
          }
          
          AQI_model_pollutant.push(data[qq].AQI_model_pollutant)
          AQI_meas_pollutant.push(data[qq].AQI_meas_pollutant)
        }
        console.log(AQI_meas)
        
        lineChartsNO2.removeSeries()
        lineChartsPM25.removeSeries()
        lineChartsAQI.removeSeries()
        lineChartsNO2.chart.xAxis[0].setCategories(xaxis)
        lineChartsPM25.chart.xAxis[0].setCategories(xaxis)
        lineChartsAQI.chart.xAxis[0].setCategories(xaxis)
         lineChartsNO2.addSeries({
              name: 'NO<sub>2</sub> meas',  
              color: 'green',
              ///yAxis: 0,
              data: NO2_meas,
             
            })
          lineChartsNO2.addSeries({
              name: 'NO<sub>2</sub> model',
              color: 'black',
              //yAxis: 1,
              data: NO2_model,
              
          })
          lineChartsPM25.addSeries({
              name: 'PM<sub>2.5</sub> meas',
              color: 'green',
             // yAxis: 0,
              data: PM25_meas
            })
          lineChartsPM25.addSeries({
              name: 'PM<sub>2.5</sub> model',
              color: 'black',
              //yAxis: 1,
              data: PM25_model
          })
          lineChartsAQI.addSeries({
              name: 'PM<sub>2.5</sub> meas',
              color: 'green',
             // yAxis: 0,
              data: AQI_meas,
              dueTo:AQI_meas_pollutant
            })
            lineChartsAQI.addSeries({
              name: 'PM<sub>2.5</sub> model',
              color: 'black',
              //yAxis: 1,
              data: AQI_model,
              dueTo:AQI_model_pollutant
          })
      })
      
      //console.log(lineCharts)
      //lineCharts.removeSeries()
      //xAxis[0].setCategories(categories)
    },
    clickPointGreece(ind){
      //console.log(ind)
      var v=this
      this.show_chart_greece=true
      this.name_of_point_greece=ind.properties.station_fullname
      axios.get("https://hsaq-eshape.eu/backend/athens_distict_year.php",{params:{station_Name: ind.properties.station_fullname}}).then(res => {
        v.years_array=res.data
        //console.log(v.years_array)
        v.selected_year=res.data[0].YearOfData
        v.ChangeSelectedYear()
      })
      //console.log(this.$refs.features)
      //this.$refs.featuresGreece.mapObject.openPopup([ind.properties.Latitude,ind.properties.Longitude]);
      //this.selected_month=null
      //this.selected_year=null
      //this.months_array=[]
      this.map_height='60vh'
      setTimeout(() => {this.$refs.myMap.mapObject.invalidateSize(); }, 700);

    },
      Reset(){
        location.reload();
        /*this.$refs.myMap.mapObject.flyTo([ 33.4808302785626, 24.4775390625],2)
        this.selected_country=null
        this.$refs.wms_layer.mapObject.setParams({layers : ''})
        this.map_height='82vh'
        this.show_chart_cities=false
        setTimeout(() => {this.$refs.myMap.mapObject.invalidateSize(); }, 700);

        */
      },
  
    ChangeSelectedGrid(){
     // console.log(this.$refs.wms_layer)
      this.$refs.wms_layer.mapObject.setParams({layers : this.selected_gridHelsinki})
    },
    ChangeSelectedGridGreece(){
      var month
      if(parseInt(this.selected_monthGreece)<10){
        month='0'+parseInt(this.selected_monthGreece)
      } else {
        month=this.selected_monthGreece
      }
      //var layer='teaser:'+this.selected_yearGreece+month+'_mean_monthly_'+this.selected_gridGreece
      var layer='teaser:'+this.selected_yearGreece+month+'_'+this.selected_gridGreece
      console.log(layer)
      console.log(this.selected_monthGreece)
      this.$refs.wms_layer.mapObject.setParams({layers :layer })
     /*
      if(this.selected_gridGreece==='pm25'){
        this.$refs.wms_layer.mapObject.setParams({layers : 'teaser:'+this.selected_yearGreece+this.selected_monthGreece+'_mean_monthly_pm25_exposure'})
      }else{
        this.$refs.wms_layer.mapObject.setParams({layers : 'teaser:201901_mean_monthly_no2_exposure'})
      }*/
    },
    ChangeSelectedCountry(){
       this.polygonSelected=null
       this.show_chart_cities=false
       this.map_height='82vh'
        if(this.selected_country==='Munich (GER)'){
          this.$refs.myMap.mapObject.flyTo([JSON.parse(this.marker_countries[0]).center[0],JSON.parse(this.marker_countries[0]).center[1]],8,30)
          this.$refs.wms_layer.mapObject.setParams({layers : ''})
          this.all_data=this.all_data_germany
          
        }else if(this.selected_country==='Helsinki (FIN)'){
          this.$refs.myMap.mapObject.flyTo([JSON.parse(this.marker_countries[1]).center[0],JSON.parse(this.marker_countries[1]).center[1]],10,30)
          this.$refs.wms_layer.mapObject.setParams({layers : this.selected_gridHelsinki})
          //this.all_data=this.all_data_germany
  
        }else if(this.selected_country==='Athens (GRC)'){
          this.$refs.myMap.mapObject.flyTo([JSON.parse(this.marker_countries[2]).center[0],JSON.parse(this.marker_countries[2]).center[1]],10,30)
          //var layer='teaser:'+this.selected_yearGreece+this.selected_monthGreece+'_mean_monthly_'+this.selected_gridGreece+'_exposure'
          var month
            if(parseInt(this.selected_monthGreece)<10){
              month='0'+parseInt(this.selected_monthGreece)
            } else {
              month=this.selected_monthGreece
            }
          var layer='teaser:'+this.selected_yearGreece+month+'_'+this.selected_gridGreece
          console.log(layer)
          this.$refs.wms_layer.mapObject.setParams({layers : layer})
        
          //this.all_data=this.all_data_germany
        }else if(this.selected_country==='Bari (IT)'){
          this.$refs.myMap.mapObject.flyTo([41.117456,16.864914],10,30)
        }
        else{
          this.$refs.myMap.mapObject.flyTo([ 33.4808302785626, 24.4775390625],2)
          this.$refs.wms_layer.mapObject.setParams({layers : ''})
          this.show_chart_cities=true
          this.$nextTick(() => {
            this.chartCities()
          })
           //if(window.innerWidth<1024){
            this.map_height='60vh'
          // }
        }
        this.show_chart_greece=false
        setTimeout(() => {this.$refs.myMap.mapObject.invalidateSize(); }, 700);
    },
    //ChangeSelectedYearCities(){
    //  this.chartCities()
    //},
    chartCities(){
      var lineCharts=this.$refs.lineChartsCitiesData
      var xaxis
      var yaxis
      //console.log(this.cities_data)
      var data=this.cities_data
      var data_city
      var ee
      
      for(var rr in data){
       // console.log(rr)
        ee=JSON.parse(data[rr])
       // console.log(ee.name)
        if(ee.name===this.selected_country){
          data_city=ee
          xaxis=ee['xAxis']
       
        }
      }
      //console.log(lineCharts.chart.series)
      if(lineCharts.chart.series.length){
        lineCharts.chart.series[0].remove()
        lineCharts.chart.series[0].remove()
      }
      //lineCharts.removeSeries()
      console.log(lineCharts)
      lineCharts.chart.xAxis[0].setCategories(xaxis)
      lineCharts.chart.setTitle(null, { text:this.selected_country });
     //console.log(lineCharts.chart)
      for(var tt in data_city){
        if(tt.includes('yAxis')){
          yaxis=data_city[tt]
          yaxis = yaxis.map(a => parseFloat(a.toFixed(2)));
          //yaxis=yaxis.toFixed(2)
          console.log(yaxis)
        var year=tt.split('_')
        lineCharts.chart.addSeries({
        //lineCharts.addSeries({
        name: year[1] ,
        //color: 'black',
        data: yaxis,
       
             
      })
       // console.log(yaxis)
        }
      }
      //console.log(xaxis)
      //console.log(yaxis)
      
      

      
    },
    CountryClick(marker_data){
      //console.log(center)
      //if(this.selected_country==='Athens'||this.selected_country==='Helsinki'||this.selected_country==='Munich'){
      //  this.$refs.myMap.mapObject.flyTo([marker_data.center[0],marker_data.center[1]],8,30)
      //}
      
      this.selected_country=marker_data.name
      this.ChangeSelectedCountry()
     
    },
    centerUpdated(){
     // console.log(center)
    },
    zoomUpdated(zoom){
      //console.log(zoom)
      this.zoom_lvl=zoom
    },
    closeNav(){
      document.getElementById("mySidenav").style.width = "0";
      
    },
    openNav(){
       document.getElementById("mySidenav").style.width = "250px";
      
    },
    ChangeSelectedAggregation(){
     
    },
    iconMarker(markers,name){
      //console.log(markers)
      var value
      if(name.includes('E-PRTR')){
        //value=markers.properties.NOx_emissions_tons/100
        return '/markers/Industrial_Sites.png'
      }else{
        value=markers.properties.NO2_TROPOMIsampling_ug_m3
      }
      //console.log(name)
      if(0<value && value<=5.9){
        return '/markers/iconsblue.png'
      }
      if(5.9<value && value<=7.5){
        return '/markers/iconsblue.png'
      }
      if(7.5<value && value<=9.1){
        return '/markers/iconsgreen.png'
      }
      if(9.1<value && value<=10.7){
        return '/markers/iconsyellow.png'
      }
      if(10.7<value && value<=12.3){
        return '/markers/iconsorange.png'
      }
      if(12.3<value){
        return '/markers/iconsred.png'
      }
    },
    colorsFunction(polygon){

      let value
      if(this.selected_aggregation==='average'){
        let val1=parseFloat(polygon['d'+this.valueYear+'mean'])
        let val2=parseFloat(polygon['d'+this.valueYear+'max'])
        value=(val1+val2)/2
      }else{
        value=polygon['d'+this.valueYear+this.selected_aggregation]
      }
      //console.log(value)
      var fillop=0.9
      var colorborder='#797979'
      var weight=1
      //console.log(polygon)
      if(this.polygonSelected){
        if(polygon.plz_id===this.polygonSelected.plz_id){
        colorborder='#C6212D'
        weight=4
      }
      }
      
      //console.log(this.polygonSelected)
      
      if(0<=value && value < 1){
        return {'color':colorborder,'weight':weight,"fillColor":"#015201",'fillOpacity':fillop}
      }
      if(1<=value && value < 2){
        return {'color':colorborder,'weight':weight,"fillColor":"#01AB01",'fillOpacity':fillop}
      }
      if(2<=value && value < 3){
        return {'color':colorborder,'weight':weight,"fillColor":"#5DD700",'fillOpacity':fillop}
      }
      if(3<=value && value < 4){
        return {'color':colorborder,'weight':weight,"fillColor":"#BBE900",'fillOpacity':fillop}
      }
      if(4<=value && value < 5){
        return {'color':colorborder,'weight':weight,"fillColor":"#FAE100",'fillOpacity':fillop}
      }
      if(5<=value && value < 6){
        return {'color':colorborder,'weight':weight,"fillColor":"#FFA500",'fillOpacity':fillop}
      }
      if(6<=value && value < 7){
        return {'color':colorborder,'weight':weight,"fillColor":"#FF6600",'fillOpacity':fillop}
      }
      if(7<=value && value < 8){
        return {'color':colorborder,'weight':weight,"fillColor":"#DF190B",'fillOpacity':fillop}
      }
      if(8<=value){
        return {'color':colorborder,'weight':weight,"fillColor":"#5B0101",'fillOpacity':fillop}
      }
     
    },
  
   
  },
  mounted(){
   
    
  },
    

}
</script>

<style>
.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #fff;
    font-family: Trebuchet MS,Helvetica,sans-serif;
    font-style: italic;
}

 .active {
    background: #b80101 !important;
    color: #ffffff !important;
  }

.loader{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background: url('../../public/img/load1.gif') 50% 50% no-repeat rgba(255,193,249,0.5)

}

.col{
    padding-right: 0px; 
    padding-left: 0px;
}
.card{
    margin-bottom: 0px;
    height:100%;
    border-radius: .0rem;
}
.chard-checkbox{
     background-color: rgba(135, 144, 152,0.8)!important;
}
.ol-zoom {
     left: unset;
     right: 8px;
}
.vc-popover-content-wrapper[data-v-05016e86]{
    z-index: 1001!important;
}
.box2{
  width: 55px; height: 25px;
}
.box3{
  width: 75px; height: 25px;
}

p{
  margin-bottom: 0rem;
}
.text{
  font-size:12px;
  color: #fff;
  margin-bottom: 0.2rem!important;
}
.vl {
  border-left: 4px solid rgb(255, 255, 255);
  height: 5px;
}
.leaflet-container {
    font: 20px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}


.leaflet-control-scale-line {
    background: #FFF!important;
}

.vue-slider-dot-tooltip-show{
  z-index: 1001!important;
}
.vue-slider-mark:first-child .vue-slider-mark-step, .vue-slider-mark:last-child .vue-slider-mark-step {
    display: block !important;; 
}
.box{
  padding: 10px 10px 15px 20px;
}

.vl {
  border-left: 4px solid rgb(255, 255, 255);
  height: 4px;
  padding: 0px 26px 0px 0px;
}
.text{
  font-size: 15px;
  color: #000000;
  padding: 0px 22px 0px 0px;
}
.vue-slider-rail {
    background-color: rgb(250, 250, 250);
}
.vue-slider-process {
    background-color: #dc3545;
}
.fade-enter-active,
.fade-leave-active {
  transition: transform 1s;
}

.fade-enter,
.fade-leave-to {
  transform: translateX(-100%);
}

.fade-enter-to,
.fade-leave {
  transform: translateX(0);
}
/*
.leaflet-top, .leaflet-right {
    width: 100%;
}*/





.active {
    background: #b8010100 !important;
    color: #05c936 !important;
  }
a{
  color: #c0c0c0 !important;
}
a:hover{
  color: #2151aa !important;
}




.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 2002;
  top: 0;
  right: 0;
  background-color: #090909;
  overflow-x: hidden;
 
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 30px;
  color: rgb(238, 238, 238)!important;
  display: block;
  transition: 0.3s;
  font-weight: 500;
}

.sidenav a:hover {
  color: #dc3545!important;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
  
}
.sm-screen{
    display: block !important;
  }
.lg-screen{
    display: none !important;
}
@media only screen and (min-width: 1024px) {
  .sm-screen{
    display: none!important;
  }
  .lg-screen{
    display: block !important;
}
}

.burger-menu {
    font-size: 30px;
    cursor: pointer;
    
    color: rgb(29, 28, 28);
    padding: 0px 15px;
    margin: 20px 0px;
}

.topleft-control{
  box-shadow: 0 0rem 0rem rgb(0 0 0 / 15%) !important;
  background: rgb(153, 164, 153);
  border-radius: 0px 0px 20px 0px;
  box-shadow: 0 0rem 3rem rgb(0 0 0 / 45%) !important;
}
.topright-control{
  box-shadow: 0 0rem 0rem rgb(0 0 0 / 15%) !important;
  background: rgb(153, 164, 153);
  border-radius: 0px 0px 0px 20px;
  box-shadow: 0 0rem 3rem rgb(0 0 0 / 45%) !important;
}
.leaflet-right .leaflet-control {
    margin-right: 0px!important;
}

/*
.leaflet-top, .leaflet-bottom{
  background: #6c757d;
  border-radius: 0px 20px 0px 0px;
  box-shadow: 0 0rem 3rem rgb(0 0 0 / 45%) !important;
}*/
.leaflet-left .leaflet-control {
     margin-left: 0px!important; 
}

.leaflet-top .leaflet-control {
    margin-top: 0px!important; 
}
.example-custom-control {
  position: absolute;
  background: #fff;
  padding: 0 1em;
  border: 1px solid #aaa;
  border-radius: 0.1em;
  top:0px;
}
.form-group {
     margin-bottom: 0rem!important;
}
.row{
  margin-right: 0px!important;
  margin-left: 0px!important;
}
.pointer {cursor: pointer;}
.card{
  background: rgba(56, 56, 56,0.7)!important;
}
.info-text{
  color:#dc3545!important;
}
.h6 {
  margin-bottom: 0.1rem!important;
}
.vertical-line{
  border-left: 1px solid rgb(235, 235, 235);
  height: 100%;
}
.leaflet-bottom .leaflet-control {
    margin-bottom: 0px!important;
}
.highcharts-navigator-xaxis{
  display: none!important;
}
/*
hr {
    margin-top: 0.1rem!important;
    margin-bottom: 0.1rem!important;
    border: 0;
    border-top: 1px solid rgba(255, 255, 255, 1)!important;
}*/


/*
 /*{
        "name":"Exposure_analysis_resume",
        "value":"teaser:Exposure_analysis_resume"
    },*/
</style>

